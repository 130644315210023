import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Title, Meta } from '@angular/platform-browser';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  faEnvelope = faEnvelope;
  faPhone = faPhone;

  formData = this.builder.group({
    first_name: new FormControl('', [Validators.required]),
    company_name: new FormControl(''),
    type: new FormControl('', [Validators.required]),
    last_name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    contact_number: new FormControl('', [Validators.required, Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$")]),
    message: new FormControl('', [Validators.required])
    });
  isSubmitted?: boolean;  
  isSending?: boolean;
 
  constructor(private builder: FormBuilder, private func: AngularFireFunctions, private toastr: ToastrService,private spinner: NgxSpinnerService,private titleService: Title, private metaService: Meta, private router: Router, private http: HttpClient) {
    this.titleService.setTitle("Contact Brandripplr | Get in Touch with Us");
    this.metaService.updateTag({ name: 'description', content: 'Have questions or need support? Reach out to Brandripplr for inquiries about influencer marketing and our services. We’re here to help.' });
   }

  ngOnInit(): void {
    this.isSending = false;
    this.isSubmitted = false;
  }

  onSubmit(formData: any) {
    this.isSending = true;
    formData.is_br = true;
    this.spinner.show();
    // request to https://jskxtziozwllyjpxoyne.supabase.co/functions/v1/contact-us post method

    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Impza3h0emlvendsbHlqcHhveW5lIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzYzNjIyNTEsImV4cCI6MTk5MTkzODI1MX0.Pw4MQ-Z99l4yb3l0rs65Os6b_nyq7cFrNLxKHFSEAMM'
      },
      observe: 'response' as 'body'
    };
    
    this.http.post<any>('https://jskxtziozwllyjpxoyne.supabase.co/functions/v1/contact-us', formData, options).subscribe(
      (response) => {
      },
      (error) => {
       if (error.status == 200) {
        this.spinner.hide();
        this.isSubmitted = true;
        this.router.navigate(['/thank-you']);
        this.isSending = false;
       }
      }
    );
    

      // this.spinner.hide();
      // this.isSending = false;
      // this.router.navigate(['/thank-you']);
    // }
    // }, error => { 
    //   console.log(error);
    //   this.spinner.hide();
    //   this.toastr.error('', 'Something went wrong, please try again later');
    //   this.isSending = false;
    // }
    
  }

  //   callable(formData).toPromise().then(res => {
  //     console.log(res);
  //     if(res == 'Sended') {
  //       this.spinner.hide();
  //       this.isSubmitted = true;
  //       // redirect to thank-you page
  //       this.router.navigate(['/thank-you']);
  //       this.isSending = false;
  //     } else {
  //       this.spinner.hide();
  //       this.toastr.error('', 'Something went wrong, please try again later');
  //       this.isSending = false;
  //     }
  //   }).catch(res => {
  //     console.log(res);
  //     this.spinner.hide();
  //     this.toastr.error('', 'Something went wrong, please try again later');
  //     this.isSending = false;
  //   });
  // }

}
