<div class="page-start">
    <app-header></app-header>
    <div class="d-flex flex-column align-items-center main-title">
        <div class="headings">
            <h1>Get Paid To Work With The Brands You Love</h1>
            <p>Become part of our exclusive network of talented creators and participate in campaigns with international
                brands.</p>
        </div>
        <div class="row gap-5 align-items-center justify-content-center">
            <app-signup-popup-button  class="btn rounded-pill contact-button contact-button-b-red d-flex justify-content-center align-items-center">Sign up for free</app-signup-popup-button>
        </div>
    </div>
</div>
<div class="container how-it-works">
    <div class="col align-items-center justify-content-center">
        <p style="font-size:34px; font-weight: bold;">How it works</p>
        <div class="row justify-content-center align-items-center" style="margin: 40px auto 0px;">
            <app-campaign-process class="col-md-6" [index]="'1'" [label]="'Apply to join'"
                [content]="'Submit your application to join Brand Ripplr for your chosen social media channel.'">
            </app-campaign-process>
            <app-campaign-process class="col-md-6" [index]="'2'" [label]="'Campaign Matchmaking'"
                [content]="'If accepted, brands can view your profile and invite you to join their campaigns.'">
            </app-campaign-process>
            <app-campaign-process class="col-md-6" [index]="'3'" [label]="'Choose your ripple'"
                [content]="'If the brand is the right fit, accept the campaign and create your content.'">
            </app-campaign-process>
            <app-campaign-process class="col-md-6" [index]="'4'" [label]="'Secure Payment'"
                [content]="'Once the brand approves your content, share it with your followers and receive payment.'">
            </app-campaign-process>
        </div>
    </div>
</div>