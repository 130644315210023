<div class="page-start">
    <app-header></app-header>
    <div class=" d-flex flex-column align-items-center main-title">
        <div class="headings">
            <h1>360 Campaign Services</h1>
            <p>Extensive experience across all influencer strategies, including paid collaborations, seeding, events, TVCs, podcasts and more.</p>
        </div>
    </div>
</div>
<div class="container services-content">
    <div class="col align-items-center justify-content-center">
        <div class="row justify-content-between">
            <app-service class="col-md-6" [iconImage]="'campaign'" [label]="'Influencer Marketing'"
                [content]="'Manage paid partnerships with talents and celebrities to create engaging content for your brand and promote it across social media channel'"></app-service>

            <app-service class="col-md-6" [iconImage]="'creative'" [label]="'Seeding'"
            [content]="'Execute seeding influencer campaigns including influencer selections, PR kit design and production, delivery and content monitoring'"></app-service>

            <app-service class="col-md-6" [iconImage]="'event'" [label]="'Events'"
            [content]="'Support with influencer events and on-ground activations.'"
           ></app-service>
            <app-service class="col-md-6" [iconImage]="'tvc'" [label]="'TVC'"
                [content]="'Television shoot management with directors and production teams.'"
               ></app-service>
               <app-service class="col-md-6" [iconImage]="'tvc'" [label]="'Podcasts'"
               [content]="'Manage podcast concept development, guest selection, and production with influencers.'"
              ></app-service>
                        
        </div>
        <div class="row justify-content-between">
            <div style="padding:50px 0; text-align: center;">
                <h2>Strategic Marketing Servies</h2>
                <p>We provide additional services that complement your overall marketing strategy and position you at the forefront of your industry</p>
            </div>
            <app-service class="col-md-6" [iconImage]="'update'" [label]="'Industry Update Webinars'"
            [content]="'Exclusive industry update webinars by Brand Ripplr to keep your finger on the pulse on the latest industry news and social media trends.'"
           ></app-service>
           <app-service class="col-md-6" [iconImage]="'group'" [label]="'Focus Groups'"
            [content]="'Influencer focus groups conducted by Brand Ripplr where influencers share their feedback and recommendations about your brand’s campaigns and provide valuable insights.'"
           ></app-service>
           <app-service class="col-md-6" [iconImage]="'negotitate'" [label]="'Influencer Playbooks'"
            [content]="'In-Depth influencer playbooks for brands, providing strategic guidance and best practices to optimise influencer collaborations.'"
           ></app-service>
            <app-service class="col-md-6" [iconImage]="'analysis'" [label]="'Competitor Analysis'"
                [content]="'Comprehensive analysis by Brand Ripplr of your industry`s regional and worldwide competitors` influencer strategies to help your brand find opportunities to stand out in the market.'"
               ></app-service>
            <app-service class="col-md-6" [iconImage]="'barter'" [label]="'Barter Campaigns'"
            [content]="'Barter campaigns on BuzzBee, the UAE’s no.1 influencer barter platform developed by Brand Ripplr with over 3,000 quality micro influencers perfect for campaign amplification'"
           ></app-service>
                        
        </div>
    </div>
</div>