<div class="page-start">
    <app-header></app-header>
    <div class="d-flex flex-column align-items-center main-title">
        <div class="headings">
            <h1>The Region’s Leading Event For Content Creators</h1>
            <p>Celebrating the region’s top talents</p>
        </div>
        <div class="page-start-video-container d-flex align-items-center">
            <video style="width: 100%;" (click)="playPause()" id="my_video_1">
                <source src="/assets/videos/content-creator-awards.mp4#t=1.8" type="video/mp4" />
            </video>
            <button *ngIf="!isPlaying" (click)="playPause()"><img src="assets/images/play.svg"></button>
        </div>
    </div>
</div>
<div class="container">
    <p>Brand Ripplr hosts the MENA Content Creator Awards every year to recognise and award the region’s talents and bring together leading brands across different industries and top content creators across MENA.</p>
    
    <p>
        <a href="https://menacreatorsawards.com" class="btn rounded-pill contact-button contact-button-b-red" style="line-height: 0.2; padding: 34px 0;" target="_blank">Find out more</a>
    </p>
</div>