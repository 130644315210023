<a style="cursor: pointer;">
    <div class="post-container">
         <video width="280px" height="280px" [muted]="'muted'" loop id="{{videoName}}" playsinline autoplay>
            <source src="https://jskxtziozwllyjpxoyne.supabase.co/storage/v1/object/public/brandripplr/web/home/influencer-content/{{videoName}}.mp4" type="video/mp4" />
        </video>
    </div>
</a>
<!--<ng-template #content let-modal>
    <div class="modal-body">
        <div class="d-flex flex-row justify-content-center align-items-center">
            <div class="d-flex flex-column align-items-center justify-content-around">
                <div class="d-flex flex-row justify-content-center align-items-center videoPlayerContainer">
                    <video width="100%" controls autoplay loop>
                        <source src="/assets/videos/{{videoName}}.mp4" type="video/mp4" />
                    </video>
                </div>
                <div class="d-flex flex-column align-items-center justify-content-start" style="width: 100%;">
                    <div class="d-flex flex-row justify-content-between" style="width: 100%;">
                        <div class="col-sm-12 col-md-6 d-flex align-items-end justify-content-center">
                            <div style="font-weight: bold;">{{influencerName}}</div>
                            <div style="font-size: 10px;margin: 0px 0px 4px 4px;">@{{videoName}}</div>
                        </div>
                        <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-center">
                            <div style="font-size: 12px;margin-right: 15px;"><span style="font-size: 12px;">{{videoLikes}}</span> Likes</div>
                            <div style="font-size: 12px;"><span style="font-size: 12px;">{{videoViews}}</span> Views</div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>
</ng-template>-->