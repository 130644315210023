<div class="about-content">
<div class="page-start">
    <app-header></app-header>
    <div class="container d-flex flex-column align-items-center" style='color:black; margin-top: 100px;'>
        <p style="font-size: 48px;font-weight: bold; text-align: center;
        background: -webkit-linear-gradient(rgb(96, 73, 215), rgb(136, 77, 223));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;">About Brand Ripplr</p>
        <div style="margin-top: 20px; max-width: 880px;">
            <p style="font-size: 20px; text-align: center;">
                Brand Ripplr is the leading global talent agency and platform. Our proprietary AI platform and campaign management services enable brands to partner with 10M+ content creators and celebrities worldwide to deliver effective influencer campaigns.
            </p><br><br>
            <p style="font-size:32px; font-weight: 900; text-align:center">Our Mission</p>
            <p style="font-size: 20px; text-align: center;">To be the region’s unrivalled leader of influencer marketing and ensure that brands partner with the right influencers to create engaging content that truly resonates with their target audiences and delivers results.</p>
            <br><br>
            <p style="font-size:32px; font-weight: 900; text-align:center">What We Stand For</p>
            <div class="list-items">
                <div class="item">
                    <strong>Deliver A World-Class Service</strong>
                    <p>We refuse to compromise on anything but excellent servicing for clients & influencers.</p>
                </div>
                <div class="item">
                    <strong>Technology Is Our Weapon</strong>
                    <p>We’re passionate about leveraging technology to revolutionise the process of influencer marketing.</p>
                </div>
                <div class="item">
                    <strong>Set The Trend</strong>
                    <p>We lead and educate the industry on the best way to produce impactful influencer marketing.</p>
                </div>
                <div class="item">
                    <strong>Empower Our People</strong>
                    <p>We invest in our people to cultivate an environment of continuous professional development and company growth.</p>
                </div>
                <div class="item">
                    <strong>Agility</strong>
                    <p>We strive for continuous incremental change - finding the smarter way of doing things.</p>
                </div>
            </div>
            <br><br>
            <p style="font-size:32px; font-weight: 900; text-align:center">Our Team Values</p>
            <div class="list-items">
                <div class="item">
                    <strong>Find A Better Way</strong>
                    <p>We harness the collective creativity and entrepreneurial spirit of our team to always look for a better way, whether that’s delivering great client service or improving our platforms and services.</p>
                </div>
                <div class="item">
                    <strong>Build Something Great</strong>
                    <p>Our people work tirelessly to find answers that work for Brand Ripplr and our clients. Together with our clients, we are committed to building something great.</p>
                </div>
                <div class="item">
                    <strong>We Win Together</strong>
                    <p>We believe in a culture where our people ‘own’ the decisions that they take. Our differences strengthen us, lead to new ways of doing things and help us meet the needs of all our clients.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="content" style="margin-top: 120px; text-align: center;">
    <div class="col align-items-center justify-content-center">
        <p style="font-size:32px; font-weight: 900;">Join Our Team</p>
        <div class="col justify-content-around align-items-center m-auto">
            <p style="font-size: 20px; text-align: center;margin: auto; margin-top: 10px;max-width: 1000px; width: 100%;text-align: center;">
                We are always looking for passionate and quality talent to strengthen our exciting and expanding team across the MENA region. Think you’d be a good fit? Check out our <a href="https://www.linkedin.com/company/brandripplr/" target="_blank">LinkedIn</a> page for opportunities.
            </p>
        </div>
    </div>
</div>
</div>