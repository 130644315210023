<div class="page-start">
  <app-header></app-header>
  <div class="container d-flex flex-column align-items-center" style="color: black; margin-top: 150px">
    <p style="
        font-size: 48px;
        font-weight: bold;
        text-align: center;
        background: -webkit-linear-gradient(
          rgb(96, 73, 215),
          rgb(136, 77, 223)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      ">
      Redirecting...
    </p>
  </div>

</div>
<div class="container contact-us-form-wrapper" style="margin-bottom:100px">
  <div class="d-flex align-items-center justify-content-center">
    <div class="col-md-11 col-lg-9 col-sm-12">
      <div class="container contact-us-form-container">
        
        <div class="submitted text-center" style="padding:0px 0">

          <!-- <h1>Thank you for getting in touch!</h1> -->
          <p></p>
        </div>
       
      </div>
    </div>
  </div>
</div>