<div class="page-start">
    <app-header></app-header>
    <div class="d-flex flex-column align-items-center main-title">
        <div class="headings">
            <h1>The Global Talent Agency and Platform</h1>
            <!-- <h2>Connecting brands with top influencers to create inspiring content</h2> -->
            <h2>Partner with top global content creators and produce creative and effective influencer marketing campaigns</h2>
        </div>

        <div class="row gap-5 align-items-center justify-content-center">
            <a href="/contact-us" target="_blank"
                class="btn rounded-pill book-demo-button">Book a demo</a>
        </div>
        <div class="page-start-video-container">
            <video width="280px" height="280px" (click)="playPause()" id="my_video_1">
                <source src="assets/home1.mp4#t=0.1" type="video/mp4" />
            </video>
            <div class="video-background" *ngIf="!isPlayed"></div>
            <button *ngIf="!isPlaying" (click)="playPause()"><img src="assets/images/play.svg"></button>
        </div>
    </div>
</div>


<div>
    <div class="content">
        <div class="col align-items-center justify-content-center achievements">
            <h2>Brand Ripplr Credentials</h2>
            <div class="row justify-content-around m-auto gap-5">
                <app-statistic-widget [label]="'Global Influencers and Celebrities'" [value]="'10M+'"></app-statistic-widget>
                <!-- <app-statistic-widget [label]="'Campaigns in MENA'" [value]="'2,000+'"></app-statistic-widget> -->
                <app-statistic-widget [label]="'Trusted Clients'" [value]="'500+'"></app-statistic-widget>
                <!-- <app-statistic-widget [label]="'Markets in MENA'" [value]="'15+'"></app-statistic-widget> -->
                <app-statistic-widget [label]="'Campaigns'" [value]="'6,000+'"></app-statistic-widget>
                <!-- <app-statistic-widget [label]="'Consumer Reach'" [value]="'100 million+'"></app-statistic-widget> -->

            </div>
        </div>
    </div>
</div>
<div class="bg-gray">
<div class="container" style="margin:50px auto 30px">
    <div class="col align-items-center justify-content-center">
        <h2 style="text-align:center; margin-bottom: 30px;">Why Brand Ripplr?</h2>
        <div class="row justify-content-between align-items-start gap-10">
            <app-sub-header class="col-lg-4" [section]="'influencer-network'" [label]="'Largest Global Influencer Network'"
                [content]="'Collaborate with 10M+ leading global content creators and celebrities.'">
            </app-sub-header>
            <app-sub-header class="col-lg-4" [section]="'campaign-manager'" [label]="'Industry Experts'"
                [content]="'Experienced management team with the latest insights on global influencer trends, platform features and regulations. In-house legal team to manage talent contracts and payments.'">
            </app-sub-header>
            <app-sub-header class="col-lg-4" [section]="'creative'" [label]="'Best-In-Class Strategies'"
                [content]="'Award-winning innovative strategies for clients, supported by data-driven insights that optimise ROI.'">
            </app-sub-header>
            <app-sub-header class="col-lg-4" [section]="'home-grow'" [label]="'Competitive Pricing'"
                [content]="'Strong relationships with influencers, celebrities, and agents, securing preferential rates. We track influencer fees on our platform using data from 6,000+ campaigns to benchmark ROI.'">
            </app-sub-header>
            <app-sub-header class="col-lg-4" [section]="'campaign-manager'" [label]="'Proprietary AI Platform'"
                [content]="'Complementary AI platform integrated with Instagram, TikTok, and YouTube APIs, providing in-depth data on creators.'">
            </app-sub-header>
            <app-sub-header class="col-lg-4" [section]="'negotitate'" [label]="'Platform Partners'"
                [content]="'Official partners for Snapchat and TikTok in the region to manage their creator campaigns.'">
            </app-sub-header>

        </div>
    </div>
</div>
</div>
<div class="content">
    <div class="col align-items-center justify-content-center campaigns">
        <h2>Campaigns by Brand Ripplr</h2>
        <div class="influencer_category_scroll">
            <div class="influencer_categories">
                <div *ngFor="let item of influencersContents | keyvalue :sortInfluencersContents" [ngClass]="{'active': activeInfluencerContentTab == item.value }" (click)="changeInfluencerContentTab(item.value)"><span > {{item.value.title}} </span></div>
            </div>
        </div>
        <div class="influencer_scroll">
            <div class="influencer_items">
                <app-social-media-content *ngFor="let content of activeInfluencerContentTab.videos"
                [videoName]="content.videoName" [influencerName]="content.influencerName"
                [videoViews]="content.videoViews" [videoLikes]="content.videoLikes">
                </app-social-media-content>
            </div>
        </div>
    </div>
</div>

<div class="bg-gray">
    <div class="content">
        <div class="align-items-center justify-content-center content-creators">
            <h2>Influencers working with Brand Ripplr</h2>
            <div class="influencer_category_scroll">
                <div class="influencer_categories">
                    <div *ngFor="let item of influencers | keyvalue" [ngClass]="{'active': activeInfluencerTab == item.key }" (click)="changeInfluencerContentTab(item.key)"><span>{{item.key}}</span></div>
                </div>
            </div>
            <div class="influencer_scroll">
                <div class="influencer_items">
                    <app-influencer-social-media-profile *ngFor="let influencer of influencers[activeInfluencerTab]" [influencer]="influencer" ></app-influencer-social-media-profile>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="content">
    <div class="col align-items-center justify-content-center trusted-clients">
        <h2>Trusted Clients</h2>
        <div class="row justify-content-around">
            <app-trusted-client-logo *ngFor="let client of clients" [clientImage]="client"></app-trusted-client-logo>
        </div>
    </div>
</div>
<div class="content">
    <div class="col align-items-center justify-content-center testimonials-container">
        <h2>Client Testimonials</h2>
        <div class="row justify-content-around testimonials-flex">
            <app-testimonial *ngFor="let t of testimonials" [testimonial]="t.testimonial" [name]="t.name" [company]="t.company"></app-testimonial>
        </div>
    </div>
</div>