import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
  param?: string;

  constructor(private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.param = this.route.snapshot.params.code;
    console.log(this.param);
    if(this.param) {
      switch(this.param) {
        case 'in':
          window.location.href = 'https://www.instagram.com/brandripplr/';
          break;
        case 'fb':
          window.location.href = 'https://www.facebook.com/BrandRipplr/';
          break;
        case 'tw':
          window.location.href = 'https://twitter.com/BrandRipplr';
          break;
        case 'li':
          window.location.href = 'https://www.linkedin.com/company/brandripplr';
          break;
        case 'yt':
          window.location.href = 'https://www.youtube.com/channel/UCvUNLvL1POHR4BzpXVqUYeA';
          break;
        case 'tt':
          window.location.href = 'https://www.tiktok.com/@brandripplr';
          break;
        case 'email-banner':
          window.location.href = 'https://menacreatorsawards.com/';
          break;
        default:
          window.location.href = 'https://www.brandripplr.com';
      }
    }

   
  }



}
